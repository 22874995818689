/**
 * EquinEdge API Staging
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AddMetricOverrideCommand { 
    date?: string;
    number?: number;
    trackAbbreviation?: string;
    trackCountry?: string;
    programNumber?: string;
    bettingNumber?: number;
    metric?: AddMetricOverrideCommand.MetricEnum;
    metricValue?: string;
    pastRaceDate?: string;
    pastRaceNumber?: number;
    pastRaceTrackAbbreviation?: string;
    pastRaceTrackCountry?: string;
    pastRaceProgramNumber?: string;
    pastRaceBettingNumber?: number;
}
export namespace AddMetricOverrideCommand {
    export type MetricEnum = 'EePick1' | 'EePick2' | 'EePickLongshot' | 'WinPercentage' | 'Pace' | 'ContestantSoR' | 'PreRaceSoR' | 'EeMl' | 'Gsr' | 'HandiviewOdds' | 'Call1LengthsBehind' | 'Call2LengthsBehind' | 'Call3LengthsBehind' | 'StretchLengthsBehind' | 'FinalLengthsBehind';
    export const MetricEnum = {
        EePick1: 'EePick1' as MetricEnum,
        EePick2: 'EePick2' as MetricEnum,
        EePickLongshot: 'EePickLongshot' as MetricEnum,
        WinPercentage: 'WinPercentage' as MetricEnum,
        Pace: 'Pace' as MetricEnum,
        ContestantSoR: 'ContestantSoR' as MetricEnum,
        PreRaceSoR: 'PreRaceSoR' as MetricEnum,
        EeMl: 'EeMl' as MetricEnum,
        Gsr: 'Gsr' as MetricEnum,
        HandiviewOdds: 'HandiviewOdds' as MetricEnum,
        Call1LengthsBehind: 'Call1LengthsBehind' as MetricEnum,
        Call2LengthsBehind: 'Call2LengthsBehind' as MetricEnum,
        Call3LengthsBehind: 'Call3LengthsBehind' as MetricEnum,
        StretchLengthsBehind: 'StretchLengthsBehind' as MetricEnum,
        FinalLengthsBehind: 'FinalLengthsBehind' as MetricEnum
    };
}