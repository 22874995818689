export const environment = {
  production: true,
  redirectAfterSignUp: {
    enable: false,
    freePass: 'https://equinedge.com/cfrm-fpva1/',
    dayPass: 'https://equinedge.com/cfrm-dpvb1/',
    weekendPass: 'https://equinedge.com/cfrm-wpvc1/',
    weekendWarrior: 'https://equinedge.com/cfrm-wwvd1/',
    unlimited: 'https://equinedge.com/cfrm-ulve1-2/',
    membership: 'https://equinedge.com/msbs-welcome/',
    pass: 'https://equinedge.com/dsbs-welcome/',
    meet: 'https://equinedge.com/tsbs-welcome/',
  },
  CLIENT_ID: 'SvIZpOyF2g6nGp3fbG3JkAFJcvGRM0cb',
  CLIENT_DOMAIN: 'equinedge.auth0.com',
  AUDIENCE: 'https://dashboard.equinedge.com',
  // REDIRECT: 'http://localhost:20001/loginCallback',
  REDIRECT: 'https://dashboard.equinedge.com/loginCallback',
  SCOPE: 'openid profile email',
  // ApiBaseUrl: 'https://dashboard-api.equinedge.com',
  ApiBaseUrl: 'https://dashboard-api.equinedge.com',
  isDevelopment: false,
  versionCheckURL: 'https://dashboard.equinedge.com/version.json',
  intercom: {
    appId: 'fx8e4g9d',
  },
  authorize_net: {
    loginId: '8ac8TNJ2b',
    clientKey: '5g84SuCNaWREQKmUs2MzVe35zR2e9cAUPqbXmc5dj35GnZ2MJ4eFtfZZVqwAZ629',
    url: 'https://js.authorize.net/v3/AcceptUI.js',
  },
  chargebee: {
    site: 'equinedge',
    apiKey: 'live_cdpUv1q89OHhgDXAjFqpNr2u56nyz0V3U',
  },
  firebase: {
    enable: true,
    showConsoleLog: false,
    enableActivityLogsToFirebase: true,
    apiKey: 'AIzaSyDoXHY4PwwURVPps36HV1ybxTBs7aj642U',
    authDomain: 'ee-dashboard-rt1.firebaseapp.com',
    databaseURL: 'https://ee-dashboard-rt1.firebaseio.com',
    projectId: 'ee-dashboard-rt1',
    storageBucket: 'ee-dashboard-rt1.appspot.com',
    messagingSenderId: '713218159604',
    appId: '1:713218159604:web:86619fc1b04a505712bfa1',
    measurementId: 'G-K2S19C8Z0J',
    url: {
      liveOdds: 'LiveOdds',
      postTimes: 'PostTimes',
      raceStatus: 'RaceStatus',
      upcomingRace: 'UpcomingRaces',
      updateResources: 'UpdateResources',
      activityLogs: 'ActivityLogs',
      buildTime: 'BuildTime',
      usersMethodsLastUpdate: 'UsersMethodsLastUpdate',
      scratches: 'Scratches',
    },
    queue: {
      liveOddsUpdates: "LiveOddsUpdates",
      raceUpdates: "RaceUpdates",
      raceStatusUpdates: "RaceStatusUpdates",
      postTimeUpdates: "PostTimeUpdates",
      resourceUpdates: "ResourceUpdates",
      dashboardUpdates: "DashboardUpdates",
      contestantScratches: "ContestantScratches",
      raceCancellations: "RaceCancellations",
      trackConditionUpdates: "TrackConditionUpdates",
      courseTypeUpdates: "CourseTypeUpdates",
      willpayUpdates: "WillpayUpdates",
      probablesUpdates: "ProbablesUpdates",
      wpsPoolsUpdates: "WpsPoolsUpdates"
    }
  },
  raceVideoSettings: {
    enable: false,
    cust: 'EquinedgeCom',
    privateKey: 'eqe64yr2364yhwer236yedr3g',
  },
  enable: {
    enableCheckGeolocation: false,
    enableTrainersEdge: false,
    enableTrainersSearch: false,
    enableLinkFeature: false,
    enableMidnightAutoRedirect: true,
    enableGoldenHours: true,
    enableServiceWorker: true,
    enableRollbar: true,
    enableGsr: true,
    enableJockey: true,
    enableTrouble: true,
    enableLifetime: true,
    enableBetButton: false,
    stronach5: true,
    enableTicketBook: false,
    enableFacebookPixelCode: true,
    enableMorningLines: true,
    enableInstallAppPopup: true,
    enableContests: false,
    enableNeedsTheLead: true,
    enableOutstandingHorses: true,
    enableNewDashboard: false,
    enableScottyPicks: false,
    enableCoastToCoast: true,
    enableProgenyPredictor: false,
    enableWeather: true,
    enableWillpayPoolProbables: true,
    enableHorseSearch: true,
    enableStarterView: true,
    enableHorsesToWatch: true,
    enableEdgeBets: false,
    enableRaceSimulator: true,
    enableAnalytics: true
  },
};
